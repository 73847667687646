.app__footer {
    flex: 1;
    width: 100%;
    flex-direction: column;
    h2{
        text-shadow: 0 1px rgba(0,0,0,.07);
        -webkit-text-fill-color: transparent;
        background-image: linear-gradient(145deg,#fff 65%,rgba(255,255,255,.43));
        -webkit-background-clip: text;
        background-clip: text;    
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);}
}

.app__footer-cards {
    width: 60%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap-reverse;
    margin: 4rem 2rem 2rem;
     .app__footer-card {
        min-width: 290px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin: 1rem 0;
        padding: 1rem;
        border-radius: 10px;
        cursor: pointer;
        background-color: var(--secondary);

        transition: all 0.3s ease-in-out;

        img {
            width: 40px;
            height: 40px;
            margin: 0 0.7rem;

        }

        p {
            font-weight: 600;

        }

        a {
            text-decoration: none;
            font-weight: 500;
        }

        &:hover {
            box-shadow: 0 0 10px var(--primary);
        }

        @media screen and (max-width: 450px) {
            width: 100%;
        }
     }

     @media screen and (max-width: 768px) {
        width: 100%;
    }
}

.app__footer-cards .app__footer-card:last-child {
    background-color: var(--secondary);
}

.app__footer-cards .app__footer-card:last-child:hover {
    box-shadow: 0 0 10px var(--primary);
  }

.app__footer-form {
    width: 60%;
    flex-direction: column;
    margin: 1rem 2rem;

    div {
        width: 100%;
        margin: 0.75rem 0;
        border-radius: 10px;
        cursor: pointer;
        background-color: var(--primary);

        transition: all 0.3S ease-in-out;

        input, textarea {
            width: 100%;
            padding: 0.95rem;
            border: none;
            border-radius: 7px;
            background-color: var(--primary);
            font-family: var(--font-base);
            color: var(--secondary);
            outline: none;
        }

        textarea {
            height: 170px;
        }

        &:hover {
            box-shadow: 0 0 10px var(--primary);
        }
    }

    button {
        padding: 1rem 2rem;
        border-radius: 10px;
        border: none;
        background-color: var(--primary);

        font-weight: 600;
        color: var(--secondary);
        outline: none;
        margin: 2rem 0 0 0;
        font-family: var(--font-base);
        cursor: pointer;
    }
    @media screen and (max-width:768px) {
        width: 100%;
        margin: 1rem 0; 
    }
}
.copyright {
    padding: 2rem;
    position:static;
     right: 0;
     bottom: 0;
     @media screen and (max-width: 450px) {
        font-weight: 100;      }
    }

