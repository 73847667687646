.app__navbar {
    width: 100% ;

    display: flex ;
    justify-content: space-between ;
    align-items: center ;

    padding: 1rem 2rem ;
    background: rgba(28, 28, 28, 0.25) ;
    -webkit-backdrop-filter: blur(5px) ;
    backdrop-filter: blur(5px) ;

    border: 0.5px solid rgba(255, 255, 255, 0.18) ;
    position: fixed ;
    z-index: 2 ;
}

.app__navbar-logo {
    display: flex ;
    justify-content: flex-start;
    align-items: center;
     img {
    width: 100px;
    height: 34x;
     }
     @media screen and (min-width: 2000px) {
        width: 180px;
        height: 40px;
     }
}

.app__navbar-links {
    flex: 1;
    display: flex ;
    justify-content: center;
    align-items: center;
    list-style: none;
        li {
        margin: 0 1rem;
        cursor: pointer;
        flex-direction: column;

             
             div {
                width: 5px;
                height: 5px;
                background: transparent;
                border-radius: 50%;
                margin-bottom: 5px;
             }
             a {
                color: var(--text);
                text-decoration: none;
                flex-direction: column;
                text-transform: uppercase;
                font-weight: 500;
                transition: all 0.3s ease-in-out;
                &:hover{
                    color: var(--accent);
                }
             }
                &:hover{
                    div {
                        background: var(--accent);
                    }
                }
            }
            @media screen and (max-width:900px) {
                display: none;
                
            }
            
}
    button {

                padding: 1rem 1.5rem;
                border-radius: 10px;
                border: none;
                background-color: var(--primary);
                font-weight: 600;
                outline: none;
                margin: 0rem 0 0 0;
                font-family: var(--font-base);   
                cursor: pointer;
                z-index: 1;
                font-size: 0.8rem;
                text-align: left;
                color: #000;
                transition: box-shadow 0.1s ease-in-out;

                &:hover {
                    box-shadow: 0 0 5px var(--primary);
                }
              
}   
.button-hide {
    @media screen and (max-width:900px) {
        display: none;
        
    }
}
.app__navbar-menu {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--primary);
    
    svg {
        width: 70%;
        height: 70%;
        color: var(--secondary);
    }
    div {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 5;
        padding: 1rem;
        width: 70%;
        height: 100vh;
        

        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex-direction: column;

        background-color: var(--background);
        background-size: cover;
        background-repeat: repeat;
        box-shadow: 0 0 20px rgba(168, 168, 168, 0.15);

        svg {
            width: 35px;
            height: 35px;
            color: var(--primary);
            margin: 0.5rem 1rem;
        }
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
        }
        li {
            margin: 1rem;
            a {
                color: var(--text);
                text-decoration: none;
                text-transform: uppercase;
                font-weight: 500;
                transition: all 0.3s ease-in-out;
                &:hover{
                    color: var(--accent);
                }
            }
        }
        @media screen and (min-width: 900px) {
            display: none;
            
        }
    }
    @media screen and (min-width: 900px) {
        display: none;
        
    }
}