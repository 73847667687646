
#home {

    .app__wrapper{
        padding: 0;
    }
}
.app__header {
    flex: 1;
    width: 100%;
    height: 100%;
    flex-direction: row;
    padding: 6rem 2rem 0;

    @media screen and (min-width: 2000px){
        padding-top: 8rem;
    }
    @media screen and (max-width:1200px) {
        flex-direction: column;
    }
    @media screen and (max-width:450px) {
        padding: 6rem 1rem 2rem;
        .app__header-circles div:nth-child(2) {
            margin: 1rem;
        }
        .app__header-circles {
            justify-content: center;
            align-items: center;
        }
    }
    @media screen and (max-width:768px) {
        .app__header-circles div:nth-child(2) {
            margin: 2rem;
        }
        .app__header-circles,
        .app__header-img {
            justify-content: center;
            align-items: center;
        }
        
    }
}

.app__header-info {
    flex: 0.65;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    @media screen and (max-width:2000px) {
        width: 100%;
        margin-right: 0rem;

    }
}
.app__header-badge {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 50vw;
  z-index: 1;

  div:nth-child(2) {
    margin: 1.75rem 0;
  }

    .badge-cmp, .tag-cmp {
        padding: 1rem 0;
        border: var(--primary);
        border-radius: 15px;
        flex-direction: row;
        width: auto;
        letter-spacing: -.2px;
    text-shadow: 0 1px rgba(0,0,0,.07);
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(145deg,#fff 65%,rgba(255,255,255,.43));
    -webkit-background-clip: text;
    background-clip: text;
    line-height: 1.08;


        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
        h1 {
          color:var(--text) ;
            width: 100%;
            text-transform: uppercase;
            text-align: center;
            font-size: 4rem;
            
            
        }
    }
    .tag-cmp {
        flex-direction: column;

        p {
          color:var(--text) ;
          width: 100%;
            text-transform: uppercase;
            text-align: center;
            font-size: 1rem;
        }
             }
       
       
        button {

          padding: 1rem 2rem;
          border-radius: 10px;
          border: none;
          width: -moz-fit-content;
          width: fit-content;
          background-color: var(--primary);
          font-size: large;
          font-weight: 600;
          color: var(--white-color);
          outline: none;
          margin: 1rem 0 0 0;
          font-family: var(--font-base);   
          cursor: pointer;
          z-index: 1;
          display: none;
        
        @media screen and (max-width:900px) {
          width: -moz-fit-content;

          width: fit-content;
          margin: 2rem 0;
          text-align: center;
          display: block;
          
        }
      }
      @media screen and (max-width: 1200px) {
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 0;

        div {
          margin: 1rem;
        }
      }
      
    @media screen and (min-width: 2000px) {
      div:nth-child(2) {
        width: auto;
        height: auto;
      }
  
      div:nth-child(3) {
        width: 170px;
        height: 170px;
      }
  
      div:nth-child(1) {
        width: auto;
        height: auto;
        font-size: x-large;
      }
    }
}

$ani-speed: 2s;
$ani-delay: 1s;

.arrow {
  opacity: 0;
  position: relative;
      top: 30%;
      cursor: pointer;
        @media screen and (max-width:800px) {
        display: none;


}
      
}

.arrow-first {
  animation: arrow-movement $ani-speed ease-in-out infinite;
}
.arrow-second {
  animation: arrow-movement $ani-speed $ani-delay ease-in-out infinite;
}

.arrow:before,
.arrow:after {
  background: #fff;
  content: '';
  display: block;
  height: 3px; 
  position: absolute;
      top: 0;
      left: 0;
  width: 30px;
}

.arrow:before {
  transform: rotate(45deg) translateX(-23%);
  transform-origin: top left;
}

.arrow:after {
  transform: rotate(-45deg) translateX(23%);
  transform-origin: top right;


}

// Animation
@keyframes arrow-movement {
  0% { 
      opacity: 0;
      top: 25%;
  }
  70% {
      opacity: 1;
  }
  100% { 
      opacity: 0;
  }
}
.hide-arrow {
  display: none;
}

$h1:  rgba(45, 45, 45, 0.101);
$yellow: #bdbdbd;
$outline: rgba(#fff, .4);
$shadow: rgba($yellow, .5);


.flicker {
	color: $h1;
  width: 100%;
  text-transform: uppercase;
  text-align: center;
  font-size: 3rem !important;

	&:before {
    content: attr(data-heading);
		position: absolute;
		color: $yellow;
		z-index: 5;
		text-shadow: none;
		text-align: center;
		animation: flicker 3s linear infinite;
	}
}

@keyframes flicker {
	0%, 19.999%, 22%, 62.999%, 64%, 64.999%, 70%, 100% {
		opacity: .99;
		text-shadow: -1px -1px 0 $outline, 1px -1px 0 $outline, -1px 1px 0 $outline, 1px 1px 0 $outline, 0 -2px 8px, 0 0 2px, 0 0 5px #bdbdbd, 0 0 2px var(--primary), 0 0 2px #bdbdbd, 0 2px 2px var(--secondary);
	}
	20%, 21.999%, 63%, 63.999%, 65%, 69.999% {
		opacity: 0.4;
		text-shadow: none;
	}
}