.app__skills {
    flex: 1;
    width: 100%;
    flex-direction: column;
    h2{
        text-shadow: 0 1px rgba(0,0,0,.07);
        -webkit-text-fill-color: transparent;
        background-image: linear-gradient(145deg,#fff 65%,rgba(255,255,255,.43));
        -webkit-background-clip: text;
        background-clip: text;    
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    }
}

.app__skills-container {
    width: 80%;
    margin-top: 3rem;

    display: flex;
    flex-direction: row;

    @media screen and (max-width:900px) {
        width: 100%;
        flex-direction: column;
    }
}

.app__skills-list {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: 5rem;

    @media screen and (max-width:900px) {
        margin-right: 0;
        justify-content: center;
        align-items: center;

    }
}

.app__skills-item {
    flex-direction: column;
    text-align: center;

    margin: 1rem;
    transition: all 0.3s ease-in-out;

    div {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        background-color: var(--secondary);

        img {
            width: 50%;
            height: 50%;
                }
            &:hover {
                box-shadow: 0 0 10px var(--primary);
            }

            @media screen and (min-width:2000px) {
              width: 150px;
              height: 150px;
            }

            @media screen and (min-width:2000px) {
                width: 70px;
                height: 70px;
              }
    }
    p {
        font-weight: 500;
        margin-top: 0.5rem;
    }
    @media screen and (min-width: 2000px) {
        margin-top: 1rem 2rem;

        p {
            margin-top: 1rem;
        }
    }
}

.head-text-experince {
    margin-top: 3rem;
    font-size: 2.75rem;
    font-weight: 800;
    text-align: center;
    color: var(--black-color);
    text-transform: capitalize;
    
  
    span {
      color: var(--secondary);
    }
  
    @media screen and (min-width: 2000px) {
      font-size: 4rem;
    }
  
    @media screen and (max-width: 450px) {
      font-size: 2rem;
    }
}






.app__skills-exp {
    flex: 1 1;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
    flex-direction: column-reverse;
    flex-wrap: wrap;
    align-content: stretch;
   
@media screen and (max-width: 900px) {
    margin-top: 2rem;

   }
 
}

.app__skills-exp-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 1rem 0;

}

.app__skills-exp-works {
    flex: 1;

    .app__skills-exp-work {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 1rem;
        cursor: pointer;

        h4 {
            font-weight: 400;
        }

        p {
            font-weight: 400;
            color: var(--primary);
            margin-top: 5px;
        }
    }
}

.app__skills-exp-year {
    margin-right: 3rem;

    p {
        font-weight: 800;
        color: var(--primary);
    }

    @media screen and (max-width: 450px) {
        margin-right: 1rem;

    }
}

.skills-tooltip {
  max-width: 300px ;
  background-color: var(--text) ;
  box-shadow: 0 0 10px var(--primary) ;
  border-radius: 5px ;
  padding: 1rem ;
  color: var(--secondary) ;
  text-align: center ;
  line-height: 1.5 ;
  opacity: 1 ;

  @media screen and (min-width: 2000px) {
    font-size: 1.75rem ;
    max-width: 500px ;
    line-height: 2 ;
  }
}

