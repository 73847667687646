.app {
    font-family: var(--font-base);
    cursor: none;
    --grey-light: #f2f7f9;
    --grey: #ecedf3;
    --black: #080808;
    --black-blue: #1f2029;

  }
  .app__navbar {
    width: 100% ;

    display: flex ;
    justify-content: space-between ;
    align-items: center ;

    padding: 1rem 1rem ;
    background: rgba(28, 28, 28, 0.25) ;
    -webkit-backdrop-filter: blur(5px) ;
    backdrop-filter: blur(5px) ;

    border: 0.5px solid rgba(255, 255, 255, 0.18) ;
    position: fixed ;
    z-index: 2 ;
    .app__navbar-links {
      flex: 1;
      display: flex ;
      justify-content: center;
      align-items: center;
      list-style: none;
          li {
          margin: 0 1rem;
          cursor: pointer;
          flex-direction: column;
  
               
               div {
                  width: 5px;
                  height: 5px;
                  background: transparent;
                  border-radius: 50%;
                  margin-bottom: 5px;
               }
               a {
                  color: var(--text);
                  text-decoration: none;
                  flex-direction: column;
                  text-transform: uppercase;
                  font-weight: 500;
                  transition: all 0.3s ease-in-out;
                  &:hover{
                      color: var(--accent);
                  }
               }
                  &:hover{
                      div {
                          background: var(--accent);
                      }
                  }
              }
              @media screen and (max-width:900px) {
                  display: none;
                  
              }
              
  }
   button {

                padding: 1rem 1.5rem;
                border-radius: 10px;
                border: none;
                background-color: var(--primary);
                font-weight: 600;
                outline: none;
                margin: 0rem 0 0 0;
                font-family: var(--font-base);   
                cursor: pointer;
                z-index: 1;
                font-size: 0.8rem;
                text-align: left;
                color: #000;
                transition: box-shadow 0.1s ease-in-out;

                &:hover {
                    box-shadow: 0 0 5px var(--primary);
                }

               
}   
  .app__navbar-menu {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--primary);
    
    svg {
        width: 70%;
        height: 70%;
        color: var(--secondary);
    }
    div {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 5;
        padding: 1rem;
        width: 70%;
        height: 100vh;
        

        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex-direction: column;

        background-color: var(--background);
        background-size: cover;
        background-repeat: repeat;
        box-shadow: 0 0 20px rgba(168, 168, 168, 0.15);

        svg {
            width: 35px;
            height: 35px;
            color: var(--primary);
            margin: 0.5rem 1rem;
        }
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
        }
        li {
            margin: 1rem;
            a {
                color: var(--text);
                text-decoration: none;
                text-transform: uppercase;
                font-weight: 500;
                transition: all 0.3s ease-in-out;
                &:hover{
                    color: var(--accent);
                }
            }
        }
        @media screen and (min-width: 900px) {
            display: none;
            
        }
    }
    @media screen and (min-width: 900px) {
        display: none;
        
    }
}
}


.cursor {
  position: fixed;
  top: 0;
  left: 0;
  width: 2.5rem;
  height: 2.5rem;
  z-index: 2;
  pointer-events: none;
  @media only screen and (max-width: 600px) {
    
        display: none;
   
}
  div {
    position: absolute;
    display: grid;
    place-items: center;
    div {
      border: 1px solid #fff;
      border-radius: 50%;
      animation: pulse 2.5s linear infinite;
      box-shadow: 0 0 50px 5px var(--accent);
    }     
  }
  div:nth-child(1), 
  div:nth-child(2) {
    width: 100%;
    height: 100%;
  }
  div:nth-child(1) {
    transition: transform 0.2s ease-out;
  }
  div:nth-child(2) {
    div {
      background: #fff;
      border-radius: 50%;
      width: 4px;
      height: 4px;
    }
    transition: transform 0.1s ease-out;
  }
}

*[data-animate="true"] {
  opacity: 0;
}

.animate-slide-down {
  animation: slide-down 300ms ease-out forwards;
}

// ----------
// ANIMATIONS
// ----------

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.25);
    opacity: 0.25;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-150%);
  }
  to {
    opacity: 1;
    transform: translateY(var(--slide-translate-y));
  }
}
  @keyframes jumbo {
    from {
        background-position: 50% 50%, 50% 50%;
    }
    to {
        background-position: 350% 50%, 350% 50%;
    }
    
  }
 
  .jumbo {
    position: absolute;
    margin-top: -10px;
    margin-right: -10px;
    z-index: 0 ;
    overflow: hidden;
    opacity: 50%;
    inset: 10px;
    height: 100vh;
    @media screen and (max-width: 600px) {
    
      display: none;


}
    --stripes: repeating-linear-gradient(
      100deg,
      #fff 0%,
      #fff 7%,
      transparent 10%,
      transparent 12%,
      #fff 16%
  );
    --stripesDark: repeating-linear-gradient(
      100deg,
      #000 0%,
      #000 7%,
      transparent 10%,
      transparent 12%,
      #000 16%
  );
  --rainbow: repeating-linear-gradient(
      100deg,
      #60a5fa 10%,
      #313bac 15%,
      #60a5fa 20%,
      #5eead4 25%,
      #60a5fa 30%
  );
  background-image: var(--stripes), var(--rainbow);
  background-size: 300%, 200%;
  background-position: 50% 50%, 50% 50%;

  filter: blur(10px) invert(100%);

  mask-image: radial-gradient(ellipse at 100% 0%, black 40%, transparent 70%);
  -webkit-mask-image: radial-gradient(ellipse at 100% 0%, black 40%, transparent 70%);

  pointer-events: none;
}

.jumbo::after {
  content: "";
  position: absolute;
  inset: 0;
 background-image: var(--stripesDark), var(--rainbow);
  filter: blur(10px) opacity(50%) saturate(200%);
  
  background-size: 200%, 100%;
  animation: jumbo 60s linear infinite;
  background-attachment: fixed;
  mix-blend-mode: difference;
}
.hide-jumbo {
  display: none;
}
  .app__container {
    width: 100%;
    min-height: 100vh;
  
    display: flex;
    flex-direction: row;
  }
  
  .app__flex {
    display: flex ;
    justify-content: center;
    align-items: center;
  }
  
  .app__wrapper {
    flex: 1;
    width: 100%;
    flex-direction: column;
    padding: 4rem 2rem;
  
    @media screen and (max-width: 450px) {
      padding: 4rem 1rem 2rem;
    }
  }
  
  .copyright {
    width: 100%;
    padding: 2rem 0 0;
  
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  
    p {
      text-transform: uppercase;
      color: var(--text);
    }
  }
  
  .head-text {
    font-size: 2.75rem;
    font-weight: 800;
    text-align: center;
    color: var(--text);
    text-transform: capitalize;
  
    span {
     -webkit-background-clip: text;
     -webkit-text-fill-color: transparent;
    }
  
    @media screen and (min-width: 2000px) {
      font-size: 3rem;
    }
  
    @media screen and (max-width: 450px) {
      font-size: 2rem;
    }
  }
  
  .p-text {
    font-size: 0.8rem;
    text-align: left;
    color: var(--primary);
    line-height: 1.5;
  
    @media screen and (min-width: 2000px) {
      font-size: 1.10rem;
    }
  }
  
  .bold-text {
    font-size: 1rem;
    font-weight: 800;
    color: var(--text);
    text-align: left;
  
    @media screen and (min-width: 2000px) {
      font-size: 1.10rem;
    }
  
    @media screen and (max-width: 450px) {
      font-size: 0.9rem;
    }
  }
  
  .app__social {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    position: sticky;
    top: 0;
  
    padding: 1rem;
  
    div {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: var(--secondary);
      margin: 0.25rem 0;
      border: 1px solid var(--secondary);
  
      display: flex;
      justify-content: center;
      align-items: center;
  
      transition: all 0.3s ease-in-out;
  
      svg {
        width: 15px;
        height: 15px;
        color: var(--primary);
      }
  
      &:hover {
        background-color: var(--primary);
        border-color: var(--primary);
  
        svg {
          color: var(--secondary);
        }
      }
  
      @media screen and (min-width: 2000px) {
        width: 50px;
        height: 50px;
  
        margin: 0.5rem 0;
  
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
  
  
  .app__navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: sticky;
    top: 0;
  
    padding: 1rem;
  
    .app__navigation-dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #cbcbcb;
      margin: 0.5rem;
  
      transition: background-color 0.2s ease-in-out;
  
      &:hover {
        background-color: var(--accent);
      }
  
      @media screen and (min-width: 2000px) {
        width: 20px;
        height: 20px;
      }
    }
    @media screen and (max-width: 450px) {
display: none;    }
  }
  

  .fixed-position {
    position: fixed;
    bottom: 0;
    right: 0;
  }
  .socials {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 2;
    padding: 1rem;
  }
  .progress-wrap {
    position: fixed;
    right: 50px;
    bottom: 15px;
    height: 46px;
    width: 46px;
    cursor: pointer;
    display: block;
    border-radius: 50px;
    box-shadow: inset  0 0 0 2px rgba(255,255,255,0.2);
    z-index: 10000;
    opacity: 0;
    visibility: hidden;
    transform: translateY(15px);
    -webkit-transition: all 200ms linear;
      transition: all 200ms linear;
  }
  .progress-wrap.active-progress {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
  .progress-wrap::after {
    position: absolute;
    font-family: 'Satoshi', sans-serif;
    content: '↑';
    text-align: center;
    line-height: 46px;
    font-size: 24px;
    color: var(--grey);
    left: 0;
    top: 0;
    height: 46px;
    width: 46px;
    cursor: pointer;
    display: block;
    z-index: 1;
    -webkit-transition: all 200ms linear;
      transition: all 200ms linear;
  }
  .progress-wrap:hover::after {
    opacity: 0;
  }
  .progress-wrap::before {
    position: absolute;
    font-family: 'Satoshi', sans-serif;
    content: '↑';
    text-align: center;
    line-height: 46px;
    font-size: 24px;
    opacity: 0;
    background-image: linear-gradient(298deg, var(--primary), var(--accent));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    left: 0;
    top: 0;
    height: 46px;
    width: 46px;
    cursor: pointer;
    display: block;
    z-index: 2;
    -webkit-transition: all 200ms linear;
      transition: all 200ms linear;
  }
  .progress-wrap:hover::before {
    opacity: 1;
  }
  .progress-wrap svg path { 
    fill: none; 
  }
  .progress-wrap svg.progress-circle path {
    stroke: var(--grey);
    stroke-width: 4;
    box-sizing:border-box;
    -webkit-transition: all 200ms linear;
      transition: all 200ms linear;
  }
  body.light .progress-wrap {
    box-shadow: inset  0 0 0 2px var(--primary);
  }
  body.light .progress-wrap::after {
    color: var(--black-blue);
  }
  body.light .progress-wrap svg.progress-circle path {
    stroke: var(--black-blue);
  }
 