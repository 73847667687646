@import url('https://uploads-ssl.webflow.com/6408bea3de5aef58b7e197d4/6408bf9d37c7748372ccf0c9_Satoshi-Variable.woff2') format('woff2');    

:root {
  --font-base: 'Satoshi', sans-serif;
   background: #000000;
   --text: #ffffff;
   --background: #000000;
   --primary: #bdbdbd;
   --secondary: #141414;
   --accent: #313bac;
   background-image: url(../src/assets/background.png);
   background-position: 50%;
   background-size: 1090px;
   position: absolute;
   top: 0%;
   bottom: 0%;
   left: 0%;
   right: 0%;
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}
.star {
  position: fixed;
  width: 3px;
  height: 3px;
  background: white;
  border-radius: 50%;
  overflow:hidden;
  z-index: 0;
}


@keyframes shooting-star {
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  100% {
    transform: translate(1000px, 1000px);
    opacity: 0;
  }
}
.star {
  animation: shooting-star 3s linear infinite;
}
