.app__about {
    flex: 1;
    width: 100%;
    flex-direction: column;
    
    h2{
        text-shadow: 0 1px rgba(0,0,0,.07);
        text-align: center;
        -webkit-text-fill-color: transparent;
        background-image: linear-gradient(145deg,#fff 65%,rgba(255,255,255,.43));
        -webkit-background-clip: text;
        background-clip: text;    
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    }
}

.app__profiles {
    display: flex;
    
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 2rem;

}

.app__profile-item {
    width: 500px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin: 2rem;
    
    img {
        width: 100%;
        height: 100%;
        border-radius: 20px;
        object-fit: cover;
    }
    @media screen and (min-width: 2000px) {
        width: 570px;
        margin: 2rem 4rem;
        img {
            height: 100%;
        }
    }
    @media screen and (max-width: 450px) {
        width: 350px;
      }
}

 .line {
    text-decoration: line-through black;
    font-size: medium;
 }

